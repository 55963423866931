import React, { useState, useEffect, Component } from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import FAQ from '../components/Molecules/FAQ/FAQ';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import Carousel from "react-elastic-carousel";


export default function Pricing({ data }) {
    debugger;
    const page = data.allContentfulZPricing.edges[0].node

    const Bold = ({ children }) => <span className="bold3">{children}</span>
    //const Text = ({ children }) => <p className="align-center">{children}</p>;

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="heading1">{children}</h1>
            ),
            [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="heading2">{children}</h2>
            ),
            [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="heading3">{children}</h3>
            ),
            [BLOCKS.HEADING_4]: (node, children) => (
                <h4 className="heading4">{children}</h4>
            ),
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <p>{children}</p>
            ),
            [BLOCKS.UL_LIST]: (node, children) => {
                return (
                    <ul
                        style={{
                            listStyle: 'disc',
                            lineHeight: '35px',
                            marginLeft: '1rem',
                        }}
                    >
                        {children.map((item) => (
                            <li key={item.key}>{item.props.children[0].props.children[0]}</li>
                        ))}
                    </ul>
                );
            },
            //[BLOCKS.UL_LIST]: (node, children) => (
            //    <span className="d-inline-block bulletSpan">{children}</span>
            //),

        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderText: (text) =>
            text
                .replace(/\u2028/g, "")
                .split("\n")
                .flatMap((text, i) => [i > 0 && <br />, text])
    }

    const {
        id,
        metaData,
        prHBtn,
        prHHd,
        prHHd2,
        prHTxt,
        prS1Col2Hd,
        prS1Col2Rtxt,
        prS1Col2Rtxtb,
        prS1Col2Rtxt2,
        prS1Col2Rtxt3,
        prS1Col2Txt,
        prS1Col2Txtb,
        prS1Col2Txt2,
        prS1Col3Hd,
        prS1Col3Rtxt,
        prS1Col3Rtxtb,
        prS1Col3Rtxt2,
        prS1Col3Rtxt3,
        prS1Col3Txt,
        prS1Col3Txtb,
        prS1Col3Txt2,
        prS1Col4Hd,
        prS1Col4Rtxt,
        prS1Col4Rtxtb,
        prS1Col4Rtxt2,
        prS1Col4Rtxt3,
        prS1Col4Txt,
        prS1Col4Txtb,
        prS1Col4Txt2,
        prS1Col5Hd,
        prS1Col5Rtxt2,
        prS1Col5Rtxt3,
        prS1Col5Image,
        prS1ColHd,
        prS1ColRtxt,
        prS1ColRtxtb,
        prS1ColRtxt2,
        prS1ColRtxt3,
        prS1ColTxt,
        prS1ColTxtb,
        prS1ColTxt2,
        prS1Txt,
        prS1Hd,
        prS1Btn,
        prS1Btn2,
        faq,
        prP2Page,
    } = page

    const [faqs, setfaqs] = useState([
        {
            question: faq.faqHd,
            answer: documentToReactComponents(faq.faqRtxt.json, options),
            open: false
        },
        {
            question: faq.faqHd2,
            answer: documentToReactComponents(faq.faqRtxt2.json, options),
            open: false
        },
        {
            question: faq.faqHd3,
            answer: documentToReactComponents(faq.faqRtxt3.json, options),
            open: false
        },
        {
            question: faq.faqHd4,
            answer: documentToReactComponents(faq.faqRtxt4.json, options),
            open: false
        },
        {
            question: faq.faqHd5,
            answer: documentToReactComponents(faq.faqRtxt5.json, options),
            open: false
        },
        {
            question: faq.faqHd6,
            answer: documentToReactComponents(faq.faqRtxt6.json, options),
            open: false
        },
        {
            question: faq.faqHd7,
            answer: documentToReactComponents(faq.faqRtxt7.json, options),
            open: false
        },
        {
            question: faq.faqHd8,
            answer: documentToReactComponents(faq.faqRtxt8.json, options),
            open: false
        },
        {
            question: faq.faqHd9,
            answer: documentToReactComponents(faq.faqRtxt9.json, options),
            open: false
        },
        {
            question: faq.faqHd10,
            answer: documentToReactComponents(faq.faqRtxt10.json, options),
            open: false
        },
        {
            question: faq.faqHd11,
            answer: documentToReactComponents(faq.faqRtxt11.json, options),
            open: false
        },
        {
            question: faq.faqHd12,
            answer: documentToReactComponents(faq.faqRtxt12.json, options),
            open: false
        },
        {
            question: faq.faqHd13,
            answer: documentToReactComponents(faq.faqRtxt13.json, options),
            open: false
        },
        {
            question: faq.faqHd14,
            answer: documentToReactComponents(faq.faqRtxt14.json, options),
            open: false
        },
    ]);

    const toggleFAQ = index => {
        setfaqs(faqs.map((faq, i) => {
            if (i === index) {
                faq.open = !faq.open
            } else {
                faq.open = false;
            }

            return faq;
        }))
    }

    //const [textToggle, textToggleState] = useState(textToggle)
    const [textToggle, textToggleState] = useState(true)


    //for scrollable pricing plan
    const [width, setWindowWidth] = useState(0)
    useEffect(() => {

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    }, [])
    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const responsive = {
        webContainer: width > 960
    }


    return (
        <Layout
            IsHeaderImageDark={false}
            navColour={"white"}>
            <head>
                <title>{metaData.title}</title>
                <meta charset="utf-8" />
                <meta name="description" content={metaData.description} />
                <meta name="keywords" content={metaData.keywords.keywords} />
                <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/pricing" />
        <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/pricing" />
            </head>


            <section className="bg-white">
                {responsive.webContainer === true ? (
                    <div className="pricing-container">
                        <div className="row pt-2 justify-content-center">
                            <h1 className="h2-huge5 text-center pt-3 h1-darkBlue">
                                {prHHd}
                            </h1>
                        </div>
                        <div className="row pt-3 justify-content-center">
                            <div className="text-center">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--outlinePurple2'
                                    buttonSize='btn--huge2'
                                    href={config.navigationURL_register}
                                >
                                    {prHBtn}{" "}
                                    <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                </Button>
                            </div>
                        </div>


                        <div className="row pt-5 align-items-center">
                            <div className="col">
                                <div className="row">
                                    <h2 className="h2-large text-darkgray">
                                        {prS1Hd}
                                    </h2>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end">
                                    <div
                                        onClick={() => textToggleState(true)}
                                    >
                                        {
                                            textToggle ?
                                                <h2 className="pr-3 h2-small text-purple3">
                                                    {prS1Btn}
                                                </h2>
                                                :
                                                <h2 className="pr-3 h2-small text-darkgray">
                                                    {prS1Btn}
                                                </h2>
                                        }
                                    </div>
                                    <h2 className="pr-3 h2-small text-darkgray">
                                        |
                                    </h2>
                                    <div onClick={() => textToggleState(false)}
                                    >
                                        {
                                            textToggle ?
                                                <h2 className="h2-small text-darkgray">
                                                    {prS1Btn2}
                                                </h2>
                                                :
                                                <h2 className="h2-small text-purple3">
                                                    {prS1Btn2}
                                                </h2>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="row pt-3">
                            <div className="colBox-Pr">
                                <div classNmae="colBox-Top col">
                                    <div className="row pt-5">
                                        <h1 className="h1-largeLight3 h1-darkBlue">
                                            {prS1ColHd}
                                        </h1>
                                    </div>
                                    <div className="row pt-3 align-items-center">
                                        <div className="col-lg-6 pl-0 pr-3">
                                            {
                                                textToggle ?
                                                    <h1 className="h1-largeLight4 h1-darkBlue">
                                                        {prS1ColTxt}
                                                    </h1>
                                                    :
                                                    <h1 className="h1-largeLight4 h1-darkBlue">
                                                        {prS1ColTxtb}
                                                    </h1>
                                            }
                                        </div>
                                        <div className="col-lg-6 pl-0">
                                            <p className="p-regular5 text-darkgray">
                                                {prS1ColTxt2}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-1">
                                        {
                                            textToggle ?
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1ColRtxt.json, options)}
                                                </p>
                                                :
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1ColRtxtb.json, options)}
                                                </p>
                                        }
                                    </div>
                                    <div className="row pt-2 pb-0">
                                        <div className="text-center">
                                            <Button
                                                className='btns'
                                                buttonStyle='btn--outlinePurple3'
                                                buttonSize='btn--large4'
                                                href={config.navigationURL_register}
                                            >
                                                {prP2Page.prS1ColBtn}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-5">
                                        <p className="p-regular5 text-darkgray4">
                                            {prP2Page.prS1ColTxt3}
                                        </p>
                                    </div>
                                </div>
                                <div className="row line pl-0 pr-0"></div>
                                <div className="row pt-0">
                                    <p className="p-medium4 h1-darkBlue">
                                        {documentToReactComponents(prS1ColRtxt2.json, options)}
                                    </p>
                                </div>
                                <div className="row p-space pt-3">
                                    <p className="p-small3 text-darkgray">
                                        {documentToReactComponents(prS1ColRtxt3.json, options)}
                                    </p>
                                </div>
                            </div>

                            <div className="colBox-Pr">
                                <div classNmae="colBox-Top">
                                    <div className="row pt-5">
                                        <h1 className="h1-largeLight3 h1-darkBlue">
                                            {prS1Col2Hd}
                                        </h1>
                                    </div>
                                    <div className="row pt-3 align-items-center">
                                        <div className="col-lg-6 pl-0 pr-3">
                                            {
                                                textToggle ?
                                                    <h1 className="h1-largeLight4 text-purple2">
                                                        {prS1Col2Txt}
                                                    </h1>
                                                    :
                                                    <h1 className="h1-largeLight4 text-purple2">
                                                        {prS1Col2Txtb}
                                                    </h1>
                                            }

                                        </div>
                                        <div className="col-lg-6 pl-0">
                                            <p className="p-regular5 text-darkgray">
                                                {prS1Col2Txt2}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-1">
                                        {
                                            textToggle ?
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1Col2Rtxt.json, options)}
                                                </p>
                                                :
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1Col2Rtxtb.json, options)}
                                                </p>
                                        }
                                    </div>
                                    <div className="row pt-2 pb-0">
                                        <div className="text-center">
                                            <Button
                                                className='btns'
                                                buttonStyle='btn--outlinePurple3'
                                                buttonSize='btn--large4'
                                                href={config.navigationURL_register}
                                            >
                                                {prP2Page.prS1Col2Btn}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-5">
                                        <p className="p-regular5 text-darkgray4">
                                            {prP2Page.prS1ColTxt3}
                                        </p>
                                    </div>
                                </div>
                                <div className="row line pl-0 pr-0"></div>
                                <div className="row pt-0">
                                    <p className="p-medium4 h1-darkBlue">
                                        {documentToReactComponents(prS1Col2Rtxt2.json, options)}
                                    </p>
                                </div>
                                <div className="row p-space pt-3">
                                    <p className="p-small3 text-darkgray">
                                        {documentToReactComponents(prS1Col2Rtxt3.json, options)}
                                    </p>
                                </div>
                            </div>

                            <div className="colBox-PrBold">
                                <div classNmae="colBox-Top">
                                    <div className="row pt-5">
                                        <h1 className="h1-largeLight3 h1-darkBlue">
                                            {prS1Col3Hd}
                                        </h1>
                                    </div>
                                    <div className="row pt-3 align-items-center">
                                        <div className="col-lg-6 pl-0 pr-3">
                                            {
                                                textToggle ?
                                                    <h1 className="h1-largeLight4 text-blue">
                                                        {prS1Col3Txt}
                                                    </h1>
                                                    :
                                                    <h1 className="h1-largeLight4 text-blue">
                                                        {prS1Col3Txtb}
                                                    </h1>
                                            }
                                        </div>
                                        <div className="col-lg-6 pl-2">
                                            <p className="p-regular5 text-darkgray">
                                                {prS1Col3Txt2}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-1">
                                        {
                                            textToggle ?
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1Col3Rtxt.json, options)}
                                                </p>
                                                :
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1Col3Rtxtb.json, options)}
                                                </p>
                                        }
                                    </div>
                                    <div className="row pt-2 pb-0">
                                        <div className="text-center">
                                            <Button
                                                className='btns'
                                                buttonStyle='btn--outlinePurple3'
                                                buttonSize='btn--large4'
                                                href={config.navigationURL_register}
                                            >
                                                {prP2Page.prS1Col3Btn}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-5">
                                        <p className="p-regular5 text-darkgray4">
                                            {prP2Page.prS1Col3Txt3}
                                        </p>
                                    </div>
                                </div>
                                <div className="row line pl-0 pr-0"></div>
                                <div className="row pt-0">
                                    <p className="p-medium4 h1-darkBlue">
                                        {documentToReactComponents(prS1Col3Rtxt2.json, options)}
                                    </p>
                                </div>
                                <div className="row p-space pt-3">
                                    <p className="p-small3 text-darkgray">
                                        {documentToReactComponents(prS1Col3Rtxt3.json, options)}
                                    </p>
                                </div>
                            </div>

                            <div className="colBox-Pr">
                                <div classNmae="colBox-Top">
                                    <div className="row pt-5">
                                        <h1 className="h1-largeLight3 h1-darkBlue">
                                            {prS1Col4Hd}
                                        </h1>
                                    </div>
                                    <div className="row pt-3 align-items-center">
                                        <div className="col-lg-6 pl-0 pr-3">
                                            {
                                                textToggle ?
                                                    <h1 className="h1-largeLight4 text-green2">
                                                        {prS1Col4Txt}
                                                    </h1>
                                                    :
                                                    <h1 className="h1-largeLight4 text-green2">
                                                        {prS1Col4Txtb}
                                                    </h1>
                                            }
                                        </div>
                                        <div className="col-lg-6 pl-2">
                                            <p className="p-regular5 text-darkgray">
                                                {prS1Col4Txt2}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-1">
                                        {
                                            textToggle ?
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1Col4Rtxt.json, options)}
                                                </p>
                                                :
                                                <p className="p-support text-darkgray">

                                                    {documentToReactComponents(prS1Col4Rtxtb.json, options)}
                                                </p>
                                        }
                                    </div>
                                    <div className="row pt-2 pb-0">
                                        <div className="text-center">
                                            <Button
                                                className='btns'
                                                buttonStyle='btn--outlinePurple3'
                                                buttonSize='btn--large4'
                                                href={config.navigationURL_register}
                                            >
                                                {prP2Page.prS1Col4Btn}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-4">
                                        <p className="p-regular5 text-darkgray4">
                                            {prP2Page.prS1Col4Txt3}
                                        </p>
                                    </div>
                                </div>
                                <div className="row line pl-0 pr-0"></div>
                                <div className="row pt-0">
                                    <p className="p-medium4 h1-darkBlue">
                                        {documentToReactComponents(prS1Col4Rtxt2.json, options)}
                                    </p>
                                </div>
                                <div className="row p-space pt-3">
                                    <p className="p-small3 text-darkgray">
                                        {documentToReactComponents(prS1Col4Rtxt3.json, options)}
                                    </p>
                                </div>
                            </div>

                            <div className="colBox-Pr">
                                <div classNmae="colBox-Top">
                                    <div className="row pt-5">
                                        <h1 className="h1-largeLight3 h1-darkBlue">
                                            {prS1Col5Hd}
                                        </h1>
                                    </div>
                                    <div className="pt-4 pb-4">
                                        <img src={prS1Col5Image.file.url} className="img-fluid" alt={prS1Col5Image.title} />
                                    </div>
                                    <div className="pb-2"></div>
                                    <div className="pb-1"></div>
                                    <div className="row pt-2 pb-0">
                                        <div className="text-center">
                                            <Button
                                                className='btns'
                                                buttonStyle='btn--outlinePurple3'
                                                buttonSize='btn--large4'
                                                href={config.navigationURL_register}
                                            >
                                                {prP2Page.prS1Col5Btn}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="row pt-4 pb-5">
                                        <p className="p-regular5 text-darkgray4">
                                            {prP2Page.prS1Col5Txt3}
                                        </p>
                                    </div>
                                </div>
                                <div className="row line pl-0 pr-0"></div>
                                <div className="row pt-0">
                                    <p className="p-medium4 h1-darkBlue">
                                        {documentToReactComponents(prS1Col5Rtxt2.json, options)}
                                    </p>
                                </div>
                                <div className="row p-space pt-3">
                                    <p className="p-small3 text-darkgray">
                                        {documentToReactComponents(prS1Col5Rtxt3.json, options)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2 text-center justify-content-center">
                            <p className="p-medium4">
                                {prS1Txt}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="pricing-container">
                        <div className="row pt-2 justify-content-center">
                            <h1 className="h2-huge5 text-center pt-3 h1-darkBlue">
                                {prHHd}
                            </h1>
                        </div>
                        <div className="row pt-3 justify-content-center">
                            <div className="text-center">
                                <Button
                                    className='btns'
                                    buttonStyle='btn--outlinePurple2'
                                    buttonSize='btn--huge2'
                                    href={config.navigationURL_register}
                                >
                                    {prHBtn}{" "}
                                    <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                                </Button>
                            </div>
                        </div>
                        <div className="row pt-3 justify-content-center">
                            <h2 className="h2-large text-center text-darkgray">
                                {prS1Hd}
                            </h2>
                        </div>

                        <div className="row pt-3">
                            <div className="col">
                                <div className="row justify-content-center">
                                    <div
                                        onClick={() => textToggleState(true)}
                                    >
                                        {
                                            textToggle ?
                                                <h2 className="pr-3 h2-small text-purple3">
                                                    {prS1Btn}
                                                </h2>
                                                :
                                                <h2 className="pr-3 h2-small text-darkgray">
                                                    {prS1Btn}
                                                </h2>
                                        }
                                    </div>
                                    <h2 className="pr-3 h2-small text-darkgray">
                                        |
                                    </h2>
                                    <div onClick={() => textToggleState(false)}
                                    >
                                        {
                                            textToggle ?
                                                <h2 className="h2-small text-darkgray">
                                                    {prS1Btn2}
                                                </h2>
                                                :
                                                <h2 className="h2-small text-purple3">
                                                    {prS1Btn2}
                                                </h2>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-wrapper">
                            <Carousel
                                showArrows={false}
                            >
                                <div className="colBox-PrMobile">
                                    <div className="justify-content-center text-center">
                                        <div className="row pt-3 pb-3 justify-content-center">
                                            <h1 className="h1-largeLight5 h1-darkBlue">
                                                {prS1ColHd}
                                            </h1>
                                        </div>
                                        <div className="row line pl-0 pr-0"></div>
                                        <div className="row pt-5 align-items-center">
                                            <div className="col-lg-6 pl-0 pr-3">
                                                {
                                                    textToggle ?
                                                        <h1 className="h1-largeLight6 h1-darkBlue">
                                                            {prS1ColTxt}
                                                        </h1>
                                                        :
                                                        <h1 className="h1-largeLight6 h1-darkBlue">
                                                            {prS1ColTxtb}
                                                        </h1>
                                                }
                                            </div>

                                        </div>
                                        <div className="pl-0">
                                            <p className="p-regular6 text-darkgray">
                                                {prS1ColTxt2}
                                            </p>
                                        </div>
                                        <div className="row justify-content-center pt-2 pb-1">
                                            {
                                                textToggle ?
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1ColRtxt.json, options)}
                                                    </p>
                                                    :
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1ColRtxtb.json, options)}
                                                    </p>
                                            }
                                        </div>

                                        <div className=" justify-content-center row pt-3">
                                            <p className="h1-largeLight7 h1-darkBlue">
                                                {documentToReactComponents(prS1ColRtxt2.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row p-space pt-4">
                                        <p className="p-small4 pl-5 text-darkgray">
                                            {documentToReactComponents(prS1ColRtxt3.json, options)}
                                        </p>
                                    </div>
                                </div>

                                <div className="colBox-PrMobile">
                                    <div className="justify-content-center text-center">
                                        <div className="row pt-3 pb-3 justify-content-center bg-purple3">
                                            <h1 className="h1-largeLight5 text-white">
                                                {prS1Col2Hd}
                                            </h1>
                                        </div>
                                        <div className="row pt-5 align-items-center">
                                            <div className="col-lg-6 pl-0 pr-3">
                                                {
                                                    textToggle ?
                                                        <h1 className="h1-largeLight6 text-purple2">
                                                            {prS1Col2Txt}
                                                        </h1>
                                                        :
                                                        <h1 className="h1-largeLight6 text-purple2">
                                                            {prS1Col2Txtb}
                                                        </h1>
                                                }
                                            </div>

                                        </div>
                                        <div className="pl-0">
                                            <p className="p-regular6 text-purple2">
                                                {prS1Col2Txt2}
                                            </p>
                                        </div>
                                        <div className="row justify-content-center pt-2 pb-1">
                                            {
                                                textToggle ?
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col2Rtxt.json, options)}
                                                    </p>
                                                    :
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col2Rtxtb.json, options)}
                                                    </p>
                                            }
                                        </div>

                                        <div className=" justify-content-center row pt-3">
                                            <p className="h1-largeLight7 h1-darkBlue">
                                                {documentToReactComponents(prS1Col2Rtxt2.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row p-space pt-4">
                                        <p className="p-small4 pl-5 text-darkgray">
                                            {documentToReactComponents(prS1Col2Rtxt3.json, options)}
                                        </p>
                                    </div>
                                </div>

                                <div className="colBox-PrMobile">
                                    <div className="justify-content-center text-center">
                                        <div className="row pt-3 pb-3 justify-content-center bg-blue2">
                                            <h1 className="h1-largeLight5 text-white">
                                                {prS1Col3Hd}
                                            </h1>
                                        </div>
                                        <div className="row pt-5 align-items-center">
                                            <div className="col-lg-6 pl-0 pr-3">
                                                {
                                                    textToggle ?
                                                        <h1 className="h1-largeLight6 text-blue">
                                                            {prS1Col3Txt}
                                                        </h1>
                                                        :
                                                        <h1 className="h1-largeLight6 text-blue">
                                                            {prS1Col3Txtb}
                                                        </h1>
                                                }
                                            </div>

                                        </div>
                                        <div className="pl-0">
                                            <p className="p-regular6 text-blue">
                                                {prS1Col3Txt2}
                                            </p>
                                        </div>
                                        <div className="row justify-content-center pt-2 pb-1">
                                            {
                                                textToggle ?
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col3Rtxt.json, options)}
                                                    </p>
                                                    :
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col3Rtxtb.json, options)}
                                                    </p>
                                            }
                                        </div>

                                        <div className=" justify-content-center row pt-3">
                                            <p className="h1-largeLight7 h1-darkBlue">
                                                {documentToReactComponents(prS1Col3Rtxt2.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row p-space pt-4">
                                        <p className="p-small4 pl-5 text-darkgray">
                                            {documentToReactComponents(prS1Col3Rtxt3.json, options)}
                                        </p>
                                    </div>
                                </div>

                                <div className="colBox-PrMobile">
                                    <div className="justify-content-center text-center">
                                        <div className="row pt-3 pb-3 justify-content-center bg-green2">
                                            <h1 className="h1-largeLight5 text-white">
                                                {prS1Col4Hd}
                                            </h1>
                                        </div>
                                        <div className="row pt-5 align-items-center">
                                            <div className="col-lg-6 pl-0 pr-3">
                                                {
                                                    textToggle ?
                                                        <h1 className="h1-largeLight6 text-green">
                                                            {prS1Col4Txt}
                                                        </h1>
                                                        :
                                                        <h1 className="h1-largeLight6 text-green">
                                                            {prS1Col4Txtb}
                                                        </h1>
                                                }
                                            </div>

                                        </div>
                                        <div className="pl-0">
                                            <p className="p-regular6 text-green">
                                                {prS1Col4Txt2}
                                            </p>
                                        </div>
                                        <div className="row justify-content-center pt-2 pb-1">
                                            {
                                                textToggle ?
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col4Rtxt.json, options)}
                                                    </p>
                                                    :
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col4Rtxtb.json, options)}
                                                    </p>
                                            }
                                        </div>

                                        <div className=" justify-content-center row pt-3">
                                            <p className="h1-largeLight7 h1-darkBlue">
                                                {documentToReactComponents(prS1Col4Rtxt2.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row p-space pt-4">
                                        <p className="p-small4 pl-5 text-darkgray">
                                            {documentToReactComponents(prS1Col4Rtxt3.json, options)}
                                        </p>
                                    </div>
                                </div>

                                <div className="colBox-PrMobile">
                                    <div className="justify-content-center text-center">
                                        <div className="row pt-3 pb-3 justify-content-center bg-darkPurple">
                                            <h1 className="h1-largeLight5 text-white">
                                                {prS1Col5Hd}
                                            </h1>
                                        </div>
                                        <div className="justify-content-center row pt-5 pb-0">
                                            <div className="text-center">
                                                <Button
                                                    className='btns'
                                                    buttonStyle='btn--outlinePurple3'
                                                    buttonSize='btn--large4'
                                                    href={config.navigationURL_register}
                                                >
                                                    {prP2Page.prS1Col5Btn}
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="row justify-content-center pt-4"></div>
                                        <div className="row justify-content-center pt-4 pb-1">
                                            {
                                                textToggle ?
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col4Rtxt.json, options)}
                                                    </p>
                                                    :
                                                    <p className="p-regular6 text-darkgray6">

                                                        {documentToReactComponents(prS1Col4Rtxtb.json, options)}
                                                    </p>
                                            }
                                        </div>

                                        <div className=" justify-content-center row pt-3">
                                            <p className="h1-largeLight7 h1-darkBlue">
                                                {documentToReactComponents(prS1Col5Rtxt2.json, options)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row p-space pt-4">
                                        <p className="p-small4 pl-5 text-darkgray">
                                            {documentToReactComponents(prS1Col5Rtxt3.json, options)}
                                        </p>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                        <div className="row pt-2 text-center justify-content-center">
                            <p className="p-medium4">
                                {prS1Txt}
                            </p>
                        </div>
                    </div>
                )}
            </section>






            <section className="bg-lightgray">
                <div className="sectionFit-container">
                    <div className="row justify-content-center ">
                        <h3 className="align-top text-center h3-pricing h1-darkBlue">
                            {faq.faqHHd}
                        </h3>
                    </div>
                    <div className="faqs">
                        {faqs.map((faq, i) => (
                            <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
                        ))}
                    </div>
                </div>
            </section>



            {/*section Footer*/}
            <section className="bg-white">
                <div className="sectionFit-container2 bg-white ">
                    <div className="row text-white pt-5 align-items-center">
                        <div className="col-lg-6 pl-5 pr-5 pb-5">
                            <h1 className="align-top h1-medium2Light text-purple">
                                {prP2Page.prFHd}
                            </h1>
                            <h1 className="h1-medium2 h1-darkBlue pt-1">
                                {prP2Page.prFTxt}
                            </h1>
                        </div>


                        <div className="col-lg-6 pl-5 pr-0 pb-5">
                            <div className="row justify-content-end pr-5">
                                <div className="text-center pr-3">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple2'
                                        buttonSize='btn--square'
                                        href={config.navigationURL_register}
                                    >
                                        {prP2Page.prFBtn}
                                    </Button>
                                </div>
                                <div className="text-center">
                                    <Button
                                        className='btns'
                                        buttonStyle='btn--outlinePurple3'
                                        buttonSize='btn--square'
                                        href={config.navigationURL_contactSales}
                                    >
                                        {prP2Page.prFBtn2}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout >
    );
}


export const query = graphql`
            query($locale: String!) {
                allContentfulZPricing(
                    filter: {
                node_locale: {eq: $locale }
    }) {
                edges {
                node {
                id
                metaData {
                slug
                    title
            description
            keywords {
                keywords
            }
                }
            prHBtn
            prHHd
            prHHd2
            prHTxt
            prS1Col2Hd
            prS1Col2Rtxt {
                json
            }
            prS1Col2Rtxtb {
                json
            }
            prS1Col2Rtxt2 {
                json
            }
            prS1Col2Rtxt3 {
                json
            }
            prS1Col2Txt
            prS1Col2Txtb
            prS1Col2Txt2
            prS1Col3Hd
            prS1Col3Rtxt {
                json
            }
            prS1Col3Rtxtb {
                json
            }
            prS1Col3Rtxt2 {
                json
            }
            prS1Col3Rtxt3 {
                json
            }
            prS1Col3Txt
            prS1Col3Txtb
            prS1Col3Txt2
            prS1Col4Hd
            prS1Col4Rtxt {
                json
            }
            prS1Col4Rtxtb {
                json
            }
            prS1Col4Rtxt2 {
                json
            }
            prS1Col4Rtxt3 {
                json
            }
            prS1Col4Txt
            prS1Col4Txtb
            prS1Col4Txt2
            prS1Col5Hd
            prS1Col5Rtxt2 {
                json
            }
            prS1Col5Rtxt3 {
                json
            }
            prS1ColHd
            prS1ColRtxt {
                json
            }
            prS1ColRtxtb {
                json
            }
            prS1ColRtxt2 {
                json
            }
            prS1ColRtxt3 {
                json
            }
            prS1ColTxt
            prS1ColTxtb
            prS1ColTxt2
            prS1Hd
            prS1Btn
            prS1Btn2
            prS1Txt
            prS1Col5Image {
                description
                            file {
                fileName
                                url
                            }
            title
                        }
            faq {
                faqHd
                    faqHd10
            faqHd11
            faqHd12
            faqHd13
            faqHd14
            faqHd2
            faqHd3
            faqHd4
            faqHd5
            faqHd6
            faqHd7
            faqHd8
            faqHd9
            faqRtxt {
                json
            }
            faqRtxt10 {
                json
            }
            faqRtxt11 {
                json
            }
            faqRtxt12 {
                json
            }
            faqRtxt13 {
                json
            }
            faqRtxt14 {
                json
            }
            faqRtxt2 {
                json
            }
            faqRtxt3 {
                json
            }
            faqRtxt4 {
                json
            }
            faqRtxt5 {
                json
            }
            faqRtxt6 {
                json
            }
            faqRtxt7 {
                json
            }
            faqRtxt8 {
                json
            }
            faqRtxt9 {
                json
            }
            faqHHd
            faqFHd
            faqFTxt
            faqFBtn
            faqFBtn2
                }
            prP2Page {
                prS1Col2Btn
                    prS1Col2Txt3
            prS1Col3Btn
            prS1Col3Txt3
            prS1Col4Btn
            prS1Col4Txt3
            prS1Col5Btn
            prS1Col5Txt3
            prS1ColBtn
            prS1ColTxt3
            prFHd
            prFTxt
            prFBtn
            prFBtn2
                  }
            }
        }
    }
}
            `
